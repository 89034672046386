export const state = {
    id: JSON.parse(sessionStorage.getItem('id')) || null,
    userName: JSON.parse(sessionStorage.getItem('userName')) || null,
    name: JSON.parse(sessionStorage.getItem('name')) || null,
    permission: JSON.parse(sessionStorage.getItem('permission')) || {}
};

export const mutations = {
    setId(state, newValue) {
        state.id = newValue
        sessionStorage.setItem('id', JSON.stringify(newValue))
    },
    setName(state, newValue) {
        state.name = newValue
        sessionStorage.setItem('name', JSON.stringify(newValue))
    },
    setPermission(state, newValue) {
        state.permission = newValue
        sessionStorage.setItem('permission', JSON.stringify(newValue))
    },
    setUserName(state, newValue) {
        state.userName = newValue
        sessionStorage.setItem('userName', JSON.stringify(newValue))
    },
};

export const getters = {
    id: state => state.id,
    userName: state => state.userName,
    name: state => state.name,
    permission: state => state.permission,
};