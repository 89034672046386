export const state = {
    id: JSON.parse(sessionStorage.getItem('UserId')) || null,
    userName: JSON.parse(sessionStorage.getItem('UserName')) || null,
    name: JSON.parse(sessionStorage.getItem('UserEmail')) || null,
    permission: JSON.parse(sessionStorage.getItem('UserPhone')) || null
};

export const mutations = {
    setId(state, newValue) {
        state.id = newValue
        sessionStorage.setItem('UserId', JSON.stringify(newValue))
    },
    setName(state, newValue) {
        state.name = newValue
        sessionStorage.setItem('UserName', JSON.stringify(newValue))
    },
    setEmail(state, newValue) {
        state.permission = newValue
        sessionStorage.setItem('UserEmail', JSON.stringify(newValue))
    },
    setPhone(state, newValue) {
        state.userName = newValue
        sessionStorage.setItem('UserPhone', JSON.stringify(newValue))
    },
};

export const getters = {
    id: state => state.id,
    name: state => state.name,
    email: state => state.email,
    phone: state => state.phone,
};